import React from 'react'
import { Link } from 'react-router-dom'

const DisonnectPage = () => {
  return (

    <div className='w-screen h-screen flex justify-center items-center'>

        <div className='flex justify-center items-center flex-col'>
            <div className='text-lg'>Company Has Been Disconnected Successfully</div>
            <Link to={"/"}><button className='bg-green-600 px-3 py-2 rounded-md text-sm mt-2 cursor-pointer text-white'>Go To Login Page</button></Link>
        </div>

    </div>

  )
}

export default DisonnectPage